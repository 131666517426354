import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const LanguageSwitcher = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <button
      onClick={toggleLanguage}
      className="px-3 py-1 rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
    >
      {language === "tr" ? "EN" : "TR"}
    </button>
  );
};

export default LanguageSwitcher;
