import React, { useContext } from "react";
import { Card, CardContent, Typography, Grid, Container } from "@mui/material";
import {
  Code,
  Security,
  People,
  CloudQueue,
  Storage,
  Router,
} from "@mui/icons-material";
import { LanguageContext } from "../context/LanguageContext";

const ServicesPage = () => {
  const { translations } = useContext(LanguageContext);
  const services = [
    {
      title: translations.servicesPage.enterpriseSoftwareDevelopment.title,
      description:
        translations.servicesPage.enterpriseSoftwareDevelopment.description,
      details: [
        translations.servicesPage.enterpriseSoftwareDevelopment.detail1,
        translations.servicesPage.enterpriseSoftwareDevelopment.detail2,
        translations.servicesPage.enterpriseSoftwareDevelopment.detail3,
        "ERP/CRM",
        "API",
      ],
      icon: <Code sx={{ fontSize: 40, color: "#2196f3" }} />,
    },
    {
      title: translations.servicesPage.cybersecurity.title,
      description: translations.servicesPage.cybersecurity.description,
      details: [
        translations.servicesPage.cybersecurity.detail1,
        translations.servicesPage.cybersecurity.detail2,
        translations.servicesPage.cybersecurity.detail3,
        translations.servicesPage.cybersecurity.detail4,
        translations.servicesPage.cybersecurity.detail5,
      ],
      icon: <Security sx={{ fontSize: 40, color: "#f44336" }} />,
    },
    {
      title: translations.servicesPage.digitalTransformation.title,
      description: translations.servicesPage.digitalTransformation.description,
      details: [
        translations.servicesPage.digitalTransformation.detail1,
        translations.servicesPage.digitalTransformation.detail2,
        translations.servicesPage.digitalTransformation.detail3,
        translations.servicesPage.digitalTransformation.detail4,
        translations.servicesPage.digitalTransformation.detail5,
      ],
      icon: <People sx={{ fontSize: 40, color: "#2962ff" }} />,
    },
    {
      title: translations.servicesPage.cloudSolutions.title,
      description: translations.servicesPage.cloudSolutions.description,
      details: [
        translations.servicesPage.cloudSolutions.detail1,
        translations.servicesPage.cloudSolutions.detail2,
        translations.servicesPage.cloudSolutions.detail3,
        translations.servicesPage.cloudSolutions.detail4,
        translations.servicesPage.cloudSolutions.detail5,
      ],
      icon: <CloudQueue sx={{ fontSize: 40, color: "#9c27b0" }} />,
    },
    {
      title: translations.servicesPage.dataAnalytics.title,
      description: translations.servicesPage.dataAnalytics.description,
      details: [
        translations.servicesPage.dataAnalytics.detail1,
        translations.servicesPage.dataAnalytics.detail2,
        translations.servicesPage.dataAnalytics.detail3,
        translations.servicesPage.dataAnalytics.detail4,
        translations.servicesPage.dataAnalytics.detail5,
      ],
      icon: <Storage sx={{ fontSize: 40, color: "#4caf50" }} />,
    },
    {
      title: translations.servicesPage.networkAndSystem.title,
      description: translations.servicesPage.networkAndSystem.description,
      details: [
        translations.servicesPage.networkAndSystem.detail1,
        translations.servicesPage.networkAndSystem.detail2,
        translations.servicesPage.networkAndSystem.detail3,
        translations.servicesPage.networkAndSystem.detail4,
        translations.servicesPage.networkAndSystem.detail5,
      ],
      icon: <Router sx={{ fontSize: 40, color: "#ff9800" }} />,
    },
  ];

  return (
    <Container sx={{ py: 20 }}>
      <Typography
        variant="h2"
        align="center"
        sx={{
          color: "#fff",
        }}
        gutterBottom
      >
        {translations.servicesPage.title}
      </Typography>

      <Typography
        align="center"
        sx={{
          mb: 6,
          maxWidth: "800px",
          mx: "auto",
          color: "#fff",
          fontSize: "1.25rem",
        }}
      >
        {translations.servicesPage.description}
      </Typography>

      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                bgcolor: "rgba(255,255,255,0.05)",
                backdropFilter: "blur(10px)",
              }}
            >
              <CardContent>
                {service.icon}
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ color: "#fff", mt: 2 }}
                  gutterBottom
                >
                  {service.title}
                </Typography>
                <Typography sx={{ color: "rgba(255,255,255,0.7)" }} paragraph>
                  {service.description}
                </Typography>
                {service.details.map((detail, i) => (
                  <Typography
                    key={i}
                    variant="body2"
                    sx={{ py: 0.5, color: "rgba(255,255,255,0.6)" }}
                  >
                    • {detail}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesPage;
