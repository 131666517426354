import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { LanguageContext } from "../context/LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("Ana Sayfa");
  const context = useContext(LanguageContext);

  const menuItems = [
    { name: "Ana Sayfa", href: "/" },
    { name: "Hizmetler", href: "/hizmetler" },
    { name: "Çözümler", href: "/cozumler" },
    { name: "İletişim", href: "/iletisim" },
  ];

  if (!context) {
    return null; // or some loading state
  }

  const { translations } = context;

  return (
    <nav className="fixed w-full glass-effect z-50">
      <div className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/">
              <img
                src="/images/globacraft_logo_v6_darktheme (1).svg"
                alt="GlobaCraft Logo"
                className="h-16 md:h-24"
              />
            </Link>
          </div>{" "}
          <div className="hidden md:flex space-x-8">
            <a
              href="/"
              className="text-gray-100 hover:text-blue-400 transition-colors"
            >
              {translations.home}
            </a>
            <a
              href="/hizmetler"
              className="text-gray-100 hover:text-blue-400 transition-colors"
            >
              {translations.services}
            </a>
            <a
              href="/cozumler"
              className="text-gray-100 hover:text-blue-400 transition-colors"
            >
              {translations.solutions}
            </a>
            <a
              href="/iletisim"
              className="text-gray-100 hover:text-blue-400 transition-colors"
            >
              {translations.contact}
            </a>
            <LanguageSwitcher />
          </div>
          <button className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transform hover:scale-105 transition-all">
            <a
              href="/iletisim"
              className="text-gray-100 hover:text-blue-400 transition-colors"
            >
              {translations.getStarted}
            </a>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
