export const translations = {
  tr: {
    // Navigation
    home: "Ana Sayfa",
    services: "Hizmetler",
    solutions: "Çözümler",
    contact: "İletişim",
    getStarted: "Başlayın",

    // Hero Section
    heroTitle: "Geleceği",
    heroTitleSpan: "Şekillendirin",
    heroDescription:
      "Sınırları aşan teknolojiler, cesur fikirler ve yenilikçi çözümlerle dijital geleceğinizi inşa ediyoruz.",
    requestDemo: "İletişime Geçin",
    explore: "Keşfet",

    // Features Section
    innovationTitle: "Hızlı ve Güvenilir Hizmet",
    // Feature 1
    quantumTitle: "Hızlı ve Güvenilir Hizmet",
    quantumDescription:
      "Müşteri odaklı ve sektöre özgü yenilikçi çözüm anlayışı",
    // Feature 2
    aiSecurityTitle: "Güvenli Altyapı",
    aiSecurityDescription:
      "Son teknolojilerin kullanıldığı güvenli uygulamalar",
    // Feature 3
    ecoTechTitle: "Deneyimli Ekip",
    ecoTechDescription:
      "Uzman ekibimiz her an yanınızda, sorularınıza anında çözüm",

    // Process Section
    processTitle1: "Nasıl",
    processTitle2: " Çalışıyoruz?",
    processSubtitle: "Başarıya Giden Yol",
    step1: {
      title: "Keşif ve Analiz",
      description:
        "İhtiyaçlarınızı derinlemesine analiz eder, hedeflerinizi belirleriz.",
    },
    step2: {
      title: "Strateji Geliştirme",
      description: "İhtiyaçlarınıza göre detaylı planlama yapıyoruz.",
    },
    step3: {
      title: "Uygulama",
      description: "Modern teknolojilerle çözümlerinizi hayata geçiririz.",
    },
    step4: {
      title: "Test ve Proje Sonlandırma",
      description: "Sürekli test ve iyileştirmelerle mükemmelliği hedefleriz.",
    },
    // CTA Section
    ctaTitle: "Geleceğe Hazır Mısınız?",
    ctaDescription:
      "Dijital dönüşüm yolculuğunuzda size rehberlik etmek için buradayız. Hemen iletişime geçin.",
    freeConsultation: "Ücretsiz Danışmanlık Alın",

    // Footer
    footer: {
      company: {
        title: "Şirket",
        about: "Hakkımızda",
        careers: "Kariyer",
        contact: "İletişim",
        blog: "Blog",
      },
      services: {
        title: "Hizmetler",
        consulting: "Danışmanlık",
        development: "Yazılım Geliştirme",
        analytics: "Veri Analitiği",
        security: "Siber Güvenlik",
      },
      legal: {
        title: "Yasal",
        privacy: "Gizlilik Politikası",
        terms: "Kullanım Şartları",
        cookies: "Çerez Politikası",
      },
      social: {
        title: "Sosyal Medya",
        followUs: "Bizi Takip Edin",
      },
      newsletter: {
        title: "Bülten",
        subtitle: "En son gelişmelerden haberdar olun",
        placeholder: "E-posta adresiniz",
        subscribe: "Abone Ol",
      },
      copyright: "© 2024 GlobaCraft. Tüm hakları saklıdır.",
      temp: "Yenilikçi teknoloji çözümleriyle geleceğinizi şekillendiriyoruz. Dijital dönüşüm yolculuğunuzda yanınızdayız.",
    },

    // Services
    servicesPage: {
      title: "Teknoloji Çözümlerimiz",
      description:
        "Sınırları aşan teknolojiler ve yenilikçi çözümlerle dijital geleceğinizi inşa ediyoruz. Her sektöre özel, ölçeklenebilir ve güvenli hizmetler sunuyoruz.",

      cybersecurity: {
        title: "Siber Güvenlik",
        description: "En son güvenlik teknolojileri ile koruma",
        detail1: "Güvenlik Duvarı",
        detail2: "Sızma Testleri",
        detail3: "Danışmanlık",
        detail4: "Şifreleme",
        detail5: "DDoS Koruması",
      },
      enterpriseSoftwareDevelopment: {
        title: "Kurumsal Yazılım Geliştirme",
        description: "İşletmenizin ihtiyaçlarına özel çözümler",
        detail1: "Web Uygulamaları",
        detail2: "Mobil Uygulamalar",
        detail3: "E-Ticaret",
      },
      digitalTransformation: {
        title: "Dijital Dönüşüm",
        description: "Teknoloji yol haritanızı planlayalım",
        detail1: "Strateji",
        detail2: "Optimizasyon",
        detail3: "Eğitim",
        detail4: "Proje Yönetimi",
        detail5: "Analiz",
      },
      cloudSolutions: {
        title: "Bulut Çözümleri",
        description: "Güvenli ve ölçeklenebilir çözümler",
        detail1: "Altyapı",
        detail2: "Sunucu",
        detail3: "Yedekleme",
        detail4: "Konteyner",
        detail5: "Mikroservis",
      },
      dataAnalytics: {
        title: "Veri Analitiği",
        description: "Verilerinizi güvenli şekilde yönetin",
        detail1: "Veritabanı",
        detail2: "Büyük Veri",
        detail3: "Migrasyon",
        detail4: "BI",
        detail5: "Veri Güvenliği",
      },
      networkAndSystem: {
        title: "Ağ ve Sistem",
        description: "Modern network altyapı çözümleri",
        detail1: "Network",
        detail2: "Entegrasyon",
        detail3: "Yük Dengeleme",
        detail4: "VPN",
        detail5: "Monitoring",
      },
    },

    // Solutions Page
    solutionsPage: {
      title: "Çözümlerimiz",
      description:
        "İşletmenizin dijital dönüşüm yolculuğunda ihtiyaç duyduğu tüm teknolojik çözümleri tek çatı altında sunuyoruz.",
      items: [
        {
          title: "Yapay Zeka & ML",
          description: "İş süreçlerinizi akıllı sistemlerle otomatikleştirin",
          features: [
            "Doğal Dil İşleme",
            "Görüntü Tanıma",
            "Tahmine Dayalı Analitik",
            "Chatbot",
          ],
        },
        {
          title: "İş Analitiği",
          description: "Verilerinizden değer üretin",
          features: [
            "Dashboard",
            "Raporlama",
            "Performans İzleme",
            "KPI Takibi",
          ],
        },
        {
          title: "DevOps",
          description: "Sürekli entegrasyon ve teslimat",
          features: ["CI/CD", "Konteynerizasyon", "Otomasyon", "Monitoring"],
        },
        {
          title: "Performans",
          description: "Yüksek performanslı sistemler",
          features: ["Yük Dengeleme", "Önbellekleme", "CDN", "Optimizasyon"],
        },
        {
          title: "IoT",
          description: "Akıllı cihaz çözümleri",
          features: [
            "Sensör Ağları",
            "Veri Toplama",
            "Uzaktan İzleme",
            "Otomasyon",
          ],
        },
        {
          title: "UX/UI",
          description: "Kullanıcı odaklı tasarımlar",
          features: [
            "Responsive Tasarım",
            "A/B Test",
            "Kullanılabilirlik",
            "Erişilebilirlik",
          ],
        },
      ],
    },

    // Contact Page
    contactPage: {
      title: "Bizimle İletişime Geçin",
      subtitle: "Projelerinizi hayata geçirmek için yanınızdayız",
      phone: "Telefon",
      email: "E-posta",
      address: "Adres",
      name: "Ad",
      surname: "Soyad",
      message: "Mesaj",
      kvkk: "KVKK Aydınlatma Metni",
      kvkkConfirm: "'ni okudum, onaylıyorum",
      send: "Gönder",
      recaptchaAlert: "Lütfen reCAPTCHA doğrulamasını tamamlayın",
      submitSuccess: "Mesajınız gönderildi",
      submitError: "Gönderim başarısız",
    },

    // Stats Section
    statsTitle: "Rakamlarla Biz",
    statsSubtitle: "Başarılarımız ve Etkimiz",
    stats: {
      clients: {
        number: "500+",
        label: "Mutlu Müşteri",
      },
      projects: {
        number: "1000+",
        label: "Tamamlanan Proje",
      },
      experience: {
        number: "15+",
        label: "Yıllık Deneyim",
      },
      awards: {
        number: "7/24",
        label: "Destek",
      },
    },
  },
  en: {
    // Navigation
    home: "Home",
    services: "Services",
    solutions: "Solutions",
    contact: "Contact",
    getStarted: "Get Started",

    // Hero Section
    heroTitle: "Shape the",
    heroTitleSpan: "Future",
    heroDescription:
      "We build your digital future with technologies that transcend boundaries, bold ideas, and innovative solutions.",
    requestDemo: "Request Demo",
    explore: "Explore",

    // Features Section
    innovationTitle: "Fast and Reliable Service",
    // Feature 1
    quantumTitle: "Fast and Reliable Service",
    quantumDescription:
      "Customer-oriented and industry-specific innovative solutions",
    // Feature 2
    aiSecurityTitle: "Secure Infrastructure",
    aiSecurityDescription: "Secure applications using the latest technologies",
    // Feature 3
    ecoTechTitle: "Experienced Team",
    ecoTechDescription:
      "Our expert team is always by your side, providing instant solutions to your questions",

    // Process Section

    processTitle1: "How",
    processTitle2: " We Work?",
    processSubtitle: "Path to Success",
    step1: {
      title: "Discovery & Analysis",
      description: "We deeply analyze your needs and define your objectives.",
    },
    step2: {
      title: "Strategy Development",
      description: "We create data-driven custom solution strategies.",
    },
    step3: {
      title: "Implementation",
      description: "We bring your solutions to life with modern technologies.",
    },
    step4: {
      title: "Test & Optimization",
      description:
        "We aim for excellence through continuous testing and improvements.",
    },

    // CTA Section
    ctaTitle: "Are You Ready for the Future?",
    ctaDescription:
      "We're here to guide you on your digital transformation journey. Get in touch now.",
    freeConsultation: "Get Free Consultation",

    // Footer
    footer: {
      company: {
        title: "Company",
        about: "About Us",
        careers: "Careers",
        contact: "Contact",
        blog: "Blog",
      },
      services: {
        title: "Services",
        consulting: "Consulting",
        development: "Development",
        analytics: "Analytics",
        security: "Security",
      },
      legal: {
        title: "Legal",
        privacy: "Privacy Policy",
        terms: "Terms of Service",
        cookies: "Cookie Policy",
      },
      social: {
        title: "Social",
        followUs: "Follow Us",
      },
      newsletter: {
        title: "Newsletter",
        subtitle: "Stay up to date with our latest news",
        placeholder: "Your email",
        subscribe: "Subscribe",
      },
      copyright: "© 2024 GlobaCraft. All rights reserved.",
      temp: "We shape your future with innovative technology solutions. We are with you on your digital transformation journey.",
    },

    // Services
    servicesPage: {
      title: "Technology Solutions",
      description:
        "We build your digital future with technologies and innovative solutions that transcend boundaries. We offer scalable and secure services specific to each sector.",

      cybersecurity: {
        title: "Cyber Security",
        description: "Protection with the latest security technologies",
        detail1: "Firewall",
        detail2: "Penetration Testing",
        detail3: "Consulting",
        detail4: "Encryption",
        detail5: "DDoS Protection",
      },
      enterpriseSoftwareDevelopment: {
        title: "Enterprise Software Development",
        description: "Special solutions for your business needs",
        detail1: "Web Applications",
        detail2: "Mobile Applications",
        detail3: "E-Commerce",
      },
      digitalTransformation: {
        title: "Digital Transformation",
        description: "Let's plan your technology roadmap",
        detail1: "Strategy",
        detail2: "Optimization",
        detail3: "Training",
        detail4: "Project Management",
        detail5: "Analysis",
      },
      cloudSolutions: {
        title: "Cloud Solutions",
        description: "Secure and scalable solutions",
        detail1: "Infrastructure",
        detail2: "Server",
        detail3: "Backup",
        detail4: "Container",
        detail5: "Microservice",
      },
      dataAnalytics: {
        title: "Data Analytics",
        description: "Manage your data securely",
        detail1: "Database",
        detail2: "Big Data",
        detail3: "Migration",
        detail4: "BI",
        detail5: "Data Security",
      },
      networkAndSystem: {
        title: "Network and System",
        description: "Modern network infrastructure solutions",
        detail1: "Network",
        detail2: "Integration",
        detail3: "Load Balancing",
        detail4: "VPN",
        detail5: "Monitoring",
      },
    },

    // Solutions Page
    solutionsPage: {
      title: "Our Solutions",
      description:
        "We offer all the technological solutions your business needs under one roof on its digital transformation journey.",
      items: [
        {
          title: "AI & ML",
          description:
            "Automate your business processes with intelligent systems",
          features: [
            "Natural Language Processing",
            "Image Recognition",
            "Predictive Analytics",
            "Chatbot",
          ],
        },
        {
          title: "Business Analytics",
          description: "Generate value from your data",
          features: [
            "Dashboard",
            "Reporting",
            "Performance Monitoring",
            "KPI Tracking",
          ],
        },
        {
          title: "DevOps",
          description: "Continuous integration and delivery",
          features: ["CI/CD", "Containerization", "Automation", "Monitoring"],
        },
        {
          title: "Performance",
          description: "High-performance systems",
          features: ["Load Balancing", "Caching", "CDN", "Optimization"],
        },
        {
          title: "IoT",
          description: "Smart device solutions",
          features: [
            "Sensor Networks",
            "Data Collection",
            "Remote Monitoring",
            "Automation",
          ],
        },
        {
          title: "UX/UI",
          description: "User-centric designs",
          features: [
            "Responsive Design",
            "A/B Testing",
            "Usability",
            "Accessibility",
          ],
        },
      ],
    },
    // Contact Page
    contactPage: {
      title: "Get in Touch",
      subtitle: "We're here to help bring your projects to life",
      phone: "Phone",
      email: "Email",
      address: "Address",
      name: "Name",
      surname: "Surname",
      message: "Message",
      kvkk: "KVKK Information Text",
      kvkkConfirm: ", I have read and approve",
      send: "Send",
      recaptchaAlert: "Please complete the reCAPTCHA verification",
      submitSuccess: "Your message has been sent",
      submitError: "Submission failed",
    },

    // Stats Section
    statsTitle: "Our Impact in Numbers",
    statsSubtitle: "Our Achievements and Reach",
    stats: {
      clients: {
        number: "500+",
        label: "Happy Clients",
      },
      projects: {
        number: "1000+",
        label: "Completed Projects",
      },
      experience: {
        number: "15+",
        label: "Years Experience",
      },
      awards: {
        number: "7/24",
        label: "Support",
      },
    },
  },
};
