import React, { createContext, useState } from "react";
import { translations } from "../translations";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("tr");

  const toggleLanguage = () => {
    setLanguage(language === "tr" ? "en" : "tr");
  };

  const value = {
    language,
    toggleLanguage,
    translations: translations[language],
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
