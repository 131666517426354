import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const ProcessSection = () => {
  const { translations } = useContext(LanguageContext);

  return (
    <section className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-16 text-white">
          {translations.processTitle1}
          <span className="text-[#FF5A5F]"> {translations.processTitle2}</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="text-center">
            <div className="w-16 h-16 bg-[#2B4EA2] rounded-full flex items-center justify-center mx-auto mb-4 text-2xl text-white hover:bg-[#FF5A5F] transition-colors">
              1
            </div>
            <h3 className="text-xl font-bold text-white mb-2">
              {translations.step1.title}
            </h3>
            <p className="text-gray-300">{translations.step1.description} </p>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-[#2B4EA2] rounded-full flex items-center justify-center mx-auto mb-4 text-2xl text-white hover:bg-[#FF5A5F] transition-colors">
              2
            </div>
            <h3 className="text-xl font-bold text-white mb-2">
              {translations.step2.title}
            </h3>
            <p className="text-gray-300">{translations.step2.description} </p>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-[#2B4EA2] rounded-full flex items-center justify-center mx-auto mb-4 text-2xl text-white hover:bg-[#FF5A5F] transition-colors">
              3
            </div>
            <h3 className="text-xl font-bold text-white mb-2">
              {" "}
              {translations.step3.title}
            </h3>
            <p className="text-gray-300">{translations.step3.description} </p>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-[#2B4EA2] rounded-full flex items-center justify-center mx-auto mb-4 text-2xl text-white hover:bg-[#FF5A5F] transition-colors">
              4
            </div>
            <h3 className="text-xl font-bold text-white mb-2">
              {" "}
              {translations.step4.title}
            </h3>
            <p className="text-gray-300">{translations.step4.description} </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
